import React, { memo } from 'react';
import { useSelector } from 'react-redux';

// import helper
import { swissNumberFormat } from '../../../helpers/swissNumberFormat';

// import utils
import { _t }      from '../../../utils/i18n';
import { getUser } from '../../../utils/Auth';

// import icons
import { IconCaretDown, IconCaretUp } from '@tabler/icons-react';

// import components
import TopStatsItemBottomText from './TopStatsItemBottomText';

const TopStatsItem = memo( ( { data } ) => {
  const { amount, currency, dynamic, dynamicPeriod, dynamicValue, title } = data;
  const isFloat = amount % 1 !== 0;

  // get the global state
  const { isRemembered } = useSelector( state => state.login );

  // get the user currency
  const { currency: userCurrency } = getUser( isRemembered );
  const isCurrency   = currency ? userCurrency : '';
  const getAmount    = isFloat ? swissNumberFormat( amount.toFixed( 2 ) ) : swissNumberFormat( amount );
  const currencyText = isCurrency + ' ' + getAmount;
  const titleEnding  = currency ? '_amount' : '';
  let titleString    = 'topstats_' + title;

  if ( !title.includes( '_amount' ) ) {
    titleString = titleString + titleEnding;
  }

  return (
    <div className="top-stats__item">
      <span className="top-stats__item__title">{ _t( titleString  ) }</span>

      <div className="top-stats__item__statistics">
        <div className="top-stats__item__statistics__left">
          <p className="top-stats__item__amount"
             title={ currencyText }>
            { currencyText }
          </p>

          <div className="top-stats__item__percentage">
            <div className="top-stats__item__dynamic">
              { dynamic === 'positive' ?
                <IconCaretUp color="#24CE54" />
              :
                <IconCaretDown color="#EF353F" />
              }
              <span className={ `top-stats__item__dynamic__value ${ dynamic === 'positive' ? 'top-stats__item__dynamic__value--is-positive' : 'top-stats__item__dynamic__value--is-negative' }` }>{ dynamicValue }%</span>
            </div>
            <TopStatsItemBottomText dynamicPeriod={ dynamicPeriod } />
          </div>
        </div>

        <div className="top-stats__item__statistics__right">
          { dynamic === 'positive' ?
            <svg className="top-stats__item__dynamic-graph" width="192" height="48" viewBox="0 0 192 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M44.5 20.2581C24.9 15.0581 7.33333 36.0914 1 47.2581H191V1.25818C166 -1.24182 139.5 33.2582 107.5 40.7582C75.5 48.2582 69 26.7581 44.5 20.2581Z" fill="url(#paint0_linear_948_8030)"/>
              <path d="M1 47.1292C7.33333 35.9625 24.9 14.9292 44.5 20.1292C69 26.6292 75.5 48.1293 107.5 40.6293C139.5 33.1293 166 -1.37073 191 1.12927" stroke="#24CE54" strokeWidth="2"/>
              <defs>
                <linearGradient id="paint0_linear_948_8030" x1="96" y1="26.1289" x2="96" y2="47.1289" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#92B93D" stopOpacity="0.32"/>
                  <stop offset="1" stopColor="#92B93D" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          :
            <svg className="top-stats__item__dynamic-graph" width="192" height="48" viewBox="0 0 192 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M147.5 20.2581C167.1 15.0581 184.667 36.0914 191 47.2581H1V1.25818C26 -1.24182 52.5 33.2582 84.5 40.7582C116.5 48.2582 123 26.7581 147.5 20.2581Z" fill="url(#paint0_linear_948_8033)"/>
              <path d="M191 47.1292C184.667 35.9625 167.1 14.9292 147.5 20.1292C123 26.6292 116.5 48.1293 84.5 40.6293C52.5 33.1293 26 -1.37073 1 1.12927" stroke="#EF353F" strokeWidth="2"/>
              <defs>
                <linearGradient id="paint0_linear_948_8033" x1="96" y1="26.1289" x2="96" y2="47.1289" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#B93D3D" stopOpacity="0.32"/>
                  <stop offset="1" stopColor="#B93D3D" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          }
        </div>
      </div>
    </div>
  )
});

export default TopStatsItem;