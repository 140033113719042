import React, { useEffect } from 'react';

export default function LSVStep2({ stepCallback }) {
  useEffect( () => {
    // TODO: it's a temp thing. have to replace it after an API call is ready.
    setTimeout( () => {
      stepCallback( 3 )
    }, 5000 );
  }, [] );

  return (
    <div className="lsv-loader">
      <div className="lsv-loader__item" />
    </div>
  )
}