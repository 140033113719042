import React, { useState }  from 'react';

// import utils
import { _t } from '../../utils/i18n';

// import components
import LSVContainerWrapper from '../../components/common/LSVContainerWrapper/LSVContainerWrapper';
import LSVStep1            from '../../components/elements/LSVFormSteps/LSVStep1';
import LSVStep2            from '../../components/elements/LSVFormSteps/LSVStep2';
import LSVStep3            from '../../components/elements/LSVFormSteps/LSVStep3';

export default function LSV() {
  // define the component state
  const [currentStep, setCurrentStep] = useState( 1 );
  const [currentType, setCurrentType] = useState( 'new' );

  const processing3rdStep = ( step, type ) => {
    setCurrentStep( step );
    setCurrentType( type );
  };

  return <LSVContainerWrapper>
    <div className="lsv-top">
      <h1 className="lsv-title">
        { currentStep === 1 ? _t( 'lsv_step1_title' ) : false }
        { currentStep === 2 ? _t( 'lsv_step2_title' ) : false }
        { currentStep === 3 ? _t( 'lsv_step3_title' ) : false }
      </h1>
      <div className="lsv-steps">
        <span className="lsv-steps__text">{ _t( 'lsv_step_text' ) } { currentStep } / 3</span>
        <div className="lsv-steps__progress">
          <div className={ `lsv-steps__progress__current lsv-steps__progress__current--step-${ currentStep }` } />
        </div>
      </div>
    </div>

    { currentStep === 1 ? <LSVStep1 type={ currentType } stepCallback={ val => setCurrentStep( val ) } /> : false }
    { currentStep === 2 ? <LSVStep2 stepCallback={ val => setCurrentStep( val ) } /> : false }
    { currentStep === 3 ? <LSVStep3 stepCallback={ ( step, type ) => processing3rdStep( step, type ) } /> : false }
  </LSVContainerWrapper>;
}