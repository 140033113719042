import React, { useState }          from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
import { setData } from './store/LSVFormStepsSlice';

// import components
import FormField from '../FormField/FormField';
import Button    from '../Button/Button';

export default function LSVStep1({ type = 'new', stepCallback }) {
  // get the global state
  const {
    firstName: storeFirstName,
    lastName: storeLastName,
    street: storeStreet,
    city: storeCity,
    iban: storeIban
  } = useSelector( state => state.lsvForm );

  // define the component state
  const [firstName, setFirstName]           = useState( type === 'edit' ? storeFirstName : '' );
  const [firstNameError, setFirstNameError] = useState( false );
  const [lastName, setLastName]             = useState( type === 'edit' ? storeLastName : '' );
  const [lastNameError, setLastNameError]   = useState( false );
  const [street, setStreet]                 = useState( type === 'edit' ? storeStreet : '' );
  const [streetError, setStreetError]       = useState( false );
  const [city, setCity]                     = useState( type === 'edit' ? storeCity : '' );
  const [cityError, setCityError]           = useState( false );
  const [iban, setIban]                     = useState( type === 'edit' ? storeIban : '' );
  const [ibanError, setIbanError]           = useState( false );

  // define dispatch
  const dispatch = useDispatch();

  /**
   * Processing to go to the 2nd step
   * Validation and error handling
   */
  const handleClickNextStep = () => {
    // checking the First name field
    setFirstNameError( firstName === '' );
    let firstNameErr = firstName === '';

    // checking the Last name field
    setLastNameError( lastName === '' );
    let lastNameErr = lastName === '';

    // checking the Street field
    setStreetError( street === '' );
    let streetErr = street === '';

    // checking the City field
    setCityError( city === '' );
    let cityErr = city === '';

    // checking the IBAN field
    setIbanError( iban === '' );
    let ibanErr = iban === '';

    // if all fields are valid - go to the next step
    if ( !firstNameErr && !lastNameErr && !streetErr && !cityErr && !ibanErr ) {
      dispatch( setData({ firstName, lastName, street, city, iban }) );
      stepCallback( 2 );
    }
  };

  return (
    <div className="lsv-form">
      <div className="lsv-form__block">
        <h3 className="lsv-form__block__name">{ _t( 'lsv_transaction_block_name' ) }</h3>
        <div className="lsv-form__block__row">
          <FormField id="lsv-first-name"
                     value={ firstName }
                     placeholder={ _t( 'lsv_first_name_placeholder' ) }
                     errorMsg={ _t( 'lsv_first_name_error' ) }
                     isError={ firstNameError }
                     onChangeAction={ val => setFirstName( val ) }
                     onFocusAction={ val => val ? setFirstNameError( false ) : false }
          />

          <FormField id="lsv-last-name"
                     value={ lastName }
                     placeholder={ _t( 'lsv_last_name_placeholder' ) }
                     errorMsg={ _t( 'lsv_last_name_error' ) }
                     isError={ lastNameError }
                     onChangeAction={ val => setLastName( val ) }
                     onFocusAction={ val => val ? setLastNameError( false ) : false }
          />
        </div>

        <div className="lsv-form__block__row">
          <FormField id="lsv-street"
                     value={ street }
                     placeholder={ _t( 'lsv_street_placeholder' ) }
                     errorMsg={ _t( 'lsv_street_error' ) }
                     isError={ streetError }
                     onChangeAction={ val => setStreet( val ) }
                     onFocusAction={ val => val ? setStreetError( false ) : false }
          />

          <FormField id="lsv-ort"
                     value={ city }
                     placeholder={ _t( 'lsv_city_placeholder' ) }
                     errorMsg={ _t( 'lsv_city_error' ) }
                     isError={ cityError }
                     onChangeAction={ val => setCity( val ) }
                     onFocusAction={ val => val ? setCityError( false ) : false }
          />
        </div>

        <div className="lsv-form__block__row">
          {/* TODO: add a dropdown element */}
        </div>
      </div>

      <div className="lsv-form__block">
        <h3 className="lsv-form__block__name">{ _t( 'lsv_bank_details_block_name' ) }</h3>
        <div className="lsv-form__block__row">
          <FormField id="lsv-iban"
                     value={ iban }
                     placeholder={ _t( 'lsv_iban_placeholder' ) }
                     errorMsg={ _t( 'lsv_iban_error' ) }
                     isError={ ibanError }
                     onChangeAction={ val => setIban( val ) }
                     onFocusAction={ val => val ? setIbanError( false ) : false }
          />

          {/* TODO: add a calendar element */}
        </div>
      </div>

      <div className="lsv-form__nav lsv-form__nav--align-right">
        <Button text={ _t( 'lsv_go_to_2nd_step' ) }
                onClickAction={ () => handleClickNextStep() } />
      </div>
    </div>
  )
}