import React, { memo } from 'react';

const FormField = memo( (
  { id, placeholder, type = 'text', errorMsg, isError = false, value, isDisabled = false, onChangeAction = () => {}, onFocusAction = () => {} }
  ) => {
  return (
    <div className="form-field__field-group">
      <input className={ isError ? 'form-field__input--is-error form-field__input' : 'form-field__input' }
             id={ id }
             type={ type }
             value={ value }
             placeholder=" "
             disabled={ isDisabled }
             onChange={ e => onChangeAction( e.target.value ) }
             onFocus={ () => onFocusAction( isError ) }
      />
      <label className="form-field__label"
             htmlFor={ id }>{ placeholder }</label>
      { isError ?
        errorMsg ? <p className="form-field__error-msg">{ errorMsg }</p> : false
      :
        false }
    </div>
  )
});

export default FormField;