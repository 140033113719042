import React, { memo } from 'react';
import { NavLink }     from 'react-router-dom';

// import constants
import { LSV_PATH, LSV_DEBIT_PATH, LSV_HISTORY_PATH } from '../../../constants/paths';

// import utils
import { _t } from '../../../utils/i18n';

const LSVNav = memo( () => {
  const navArr = [
    {
      path: LSV_PATH,
      text: _t( 'lsv_nav_lsv' )
    },
    {
      path: LSV_DEBIT_PATH,
      text: _t( 'lsv_nav_debit' )
    },
    {
      path: LSV_HISTORY_PATH,
      text: _t( 'lsv_nav_history' )
    },
  ];

  return (
    <div className="lsv-nav">
      { navArr.map( item => {
        const { path, text } = item;

        return <NavLink className={ ( { isActive } ) => isActive ? 'lsv-nav__link lsv-nav__link--is-active' : 'lsv-nav__link' }
                        to={ path }
                        key={ path }>{ text }</NavLink>
      })}
    </div>
  )
});

export default LSVNav;