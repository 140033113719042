import React           from 'react';
import { useSelector } from 'react-redux';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import FormField from '../FormField/FormField';
import Button    from '../Button/Button';

export default function LSVStep3({ stepCallback }) {
  // get the global state
  const { firstName, lastName, street, city, iban } = useSelector( state => state.lsvForm );

  return (
    <div className="lsv-form">
      <div className="lsv-form__block">
        <h3 className="lsv-form__block__name">{ _t( 'lsv_transaction_block_name' ) }</h3>
        <div className="lsv-form__block__row">
          <FormField id="lsv-first-name"
                     placeholder={ _t( 'lsv_first_name_placeholder' ) }
                     errorMsg={ _t( 'lsv_first_name_error' ) }
                     value={ firstName }
                     isDisabled
          />

          <FormField id="lsv-last-name"
                     placeholder={ _t( 'lsv_last_name_placeholder' ) }
                     errorMsg={ _t( 'lsv_last_name_error' ) }
                     value={ lastName }
                     isDisabled
          />
        </div>

        <div className="lsv-form__block__row">
          <FormField id="lsv-street"
                     placeholder={ _t( 'lsv_street_placeholder' ) }
                     errorMsg={ _t( 'lsv_street_error' ) }
                     value={ street }
                     isDisabled
          />

          <FormField id="lsv-ort"
                     placeholder={ _t( 'lsv_city_placeholder' ) }
                     errorMsg={ _t( 'lsv_city_error' ) }
                     value={ city }
                     isDisabled
          />
        </div>

        <div className="lsv-form__block__row">
          {/* TODO: add a dropdown element */}
        </div>
      </div>

      <div className="lsv-form__block">
        <h3 className="lsv-form__block__name">{ _t( 'lsv_bank_details_block_name' ) }</h3>
        <div className="lsv-form__block__row">
          <FormField id="lsv-iban"
                     placeholder={ _t( 'lsv_iban_placeholder' ) }
                     errorMsg={ _t( 'lsv_iban_error' ) }
                     value={ iban }
                     isDisabled
          />

          {/* TODO: add a calendar element */}
        </div>
      </div>

      <div className="lsv-form__nav">
        <Button text={ _t( 'lsv_go_to_edit' ) }
                viewType={ 2 }
                onClickAction={ () => stepCallback( 1, 'edit' ) } />
        <Button text={ _t( 'lsv_go_to_new' ) }
                onClickAction={ () => stepCallback( 1, 'new' ) } />
      </div>
    </div>
  )
}