import React                             from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { API_URL } from '../../../constants/base';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import {
  RECURRINGPAYMENTS_FETCH, RECURRINGPAYMENTS_DATE_UPDATE, CONFIRMATION_POPUP_HIDE, RECURRINGPAYMENTS_INACTIVE,
  TOAST_SHOW, RECURRINGPAYMENTS_INTERVAL_STATUS_UPDATED
} from '../../../constants/dispatchTypes';

// import helpers
import { client }                  from '../../../api/client';
import { getPaymentTypeByKeyword } from '../../../helpers/getPaymentTypeByKeyword';
import { initialRequest, refreshTokenHelper, updateDateRequest } from '../../../api/helper';

// import utils
import { getAccessToken, isUserRemembered, getUserPackage } from '../../../utils/Auth';
import { _t, setMetaTitle }                                 from '../../../utils/i18n';

// import config
import { sortByAmount, sortByStatus, sortByNextPayment, sortByDate } from '../../../components/elements/Table/config';

// import components
import TableActions       from '../../../components/elements/Table/TableActions';
import TableStatus        from '../../../components/elements/Table/TableStatus';
import TableNameAddress   from '../../../components/elements/Table/TableNameAddress';
import TablePaymentMethod from '../../../components/elements/Table/TablePaymentMethod';

// define API urls
const defaultUrl = 'dashboard_stats_api/get-recurring-donations-initial-payments/';
const updateUrl  = 'dashboard_stats_api/get-recurring-donations-initial-payments-update/';

// define table
const tableOptions = [
  {
    id: 'actions',
    name: _t( 'table_actions' ),
    selector: row => <TableActions row={ row } />,
    width: '65px',
  },
  {
    id: 'order-nr',
    name: _t( 'table_order_num' ),
    selector: row => row.order_nr,
    sortable: true,
    width: '100px',
  },
  {
    id: 'date',
    name: _t( 'table_date' ),
    selector: row => row.date,
    sortable: true,
    width: '80px',
    sortFunction: sortByDate,
  },
  {
    id: 'status',
    name: _t( 'table_status' ),
    selector: row => <TableStatus status={ row.active } text={ row.active ? _t( 'table_active' ) : _t( 'table_inactive' ) } />,
    sortable: true,
    sortFunction: sortByStatus
  },
  {
    id: 'name',
    name: _t( 'table_name' ),
    selector: row => <TableNameAddress address={ row.full_address } />,
  },
  {
    id: 'amount',
    name: _t( 'table_amount' ),
    selector: row => row.amount,
    sortable: true,
    sortFunction: sortByAmount
  },
  {
    id: 'interval',
    name: _t( 'table_interval' ),
    selector: row => _t( 'table_interval_' + row.interval ),
  },
  {
    id: 'next-payment',
    name: _t( 'table_next_payment' ),
    selector: row => row.next_payment_date,
    sortable: true,
    sortFunction: sortByNextPayment
  },
  {
    id: 'donation-type',
    name: _t( 'table_payment_type' ),
    selector: row => <span title={ getPaymentTypeByKeyword( row.donation_type ) }>{ getPaymentTypeByKeyword( row.donation_type ) }</span>,
  },
  {
    id: 'product',
    name: getUserPackage( isUserRemembered() ) === 'smart' ? _t( 'table_product_smart' ) : _t( 'table_product' ),
    selector: row => <span title={ row.product }>{ row.product }</span>,
  },
  {
    id: 'donation-purpose',
    name: _t( 'table_donation_purpose' ),
    selector: row => row.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : row.donation_purpose,
  },
  {
    id: 'payment-method',
    name: _t( 'table_payment_method' ),
    selector: row => <TablePaymentMethod data={ row } />,
    width: '160px',
  }
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchRecurringDonations = createAsyncThunk(
  RECURRINGPAYMENTS_FETCH,
  async ( args, thunkAPI ) =>
    initialRequest( args, thunkAPI, { defaultUrl, updateUrl }, false )
);

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: RECURRINGPAYMENTS_DATE_UPDATE
};

export const updatingRecurringDonations = dateRange => updateDateRequest( dateRange, updatingData, false );

/**
 * Make request to change the recurring payment donation status
 *
 * @param id | int
 * @param paymentMethod | string
 * @param tableData | object
 * @return function
 */
export const changeRecurringPaymentStatus = ( id, paymentMethod, tableData ) => {
  // TODO: find a way how to get table data from store (not from param)
  return async function changeStatusThunk( dispatch ) {
    try {
      const isRemembered = isUserRemembered();

      await refreshTokenHelper( 'update', isRemembered, dispatch );
      await client.post(
        API_URL + 'dashboard_stats_api/cancel-recurring-payment/',
        { payment_type: paymentMethod, identifier: id },
        { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } }
      );

      const updatedTableData = tableData.map( item => {
        if ( item.identifier === id ) {
          return {...item, active: false};
        } else return item;
      });

      dispatch( { type: RECURRINGPAYMENTS_INACTIVE, payload: updatedTableData } );
      dispatch( { type: RECURRINGPAYMENTS_INTERVAL_STATUS_UPDATED, payload: IDLE_STATUS } );
      dispatch( { type: CONFIRMATION_POPUP_HIDE, payload: false } );
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'success', text: _t( 'status_changed_msg' ) } } );
    } catch ( err ) {
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
};

const recurringDonationsSlice = createSlice({
  name: 'recurringDonations',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: '',
    topStatsData: [],
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      const { payload } = action;

      state.status      = payload;
      state.tableStatus = payload;
    },
    dateUpdated: ( state, action ) => {
      const { topStatsData, tableData } = action.payload;

      state.topStatsData = topStatsData !== undefined ? topStatsData : state.topStatsData;
      state.tableData    = tableData !== undefined ? tableData : state.tableData;
      state.status       = SUCCEEDED_STATUS;
      state.tableStatus  = SUCCEEDED_STATUS;
    },
    makePaymentInactive: ( state, action ) => {
      const { payload } = action;

      state.tableData = payload !== undefined ? payload : state.tableData;
    }
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchRecurringDonations.pending, state => {
        state.status = LOADING_STATUS;
      })
      .addCase( fetchRecurringDonations.fulfilled, ( state, action ) => {
        const { topStatsData, tableData, metas } = action.payload;

        state.metaTitle    = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topStatsData = topStatsData !== undefined ? topStatsData : state.topStatsData;
        state.tableData    = tableData !== undefined ? tableData : state.tableData;
        state.status       = SUCCEEDED_STATUS;
        state.tableStatus  = SUCCEEDED_STATUS;
      })
      .addCase( fetchRecurringDonations.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const { statusUpdated: recurringDonationsStatusUpdating } = recurringDonationsSlice.actions;

export default recurringDonationsSlice.reducer