import React, { memo } from 'react';
import { Helmet }      from 'react-helmet';

// import images
import image from '../../../assets/images/lsv-hero-image.jpeg';

// import components
import HeroImage from '../../../components/elements/HeroImage/HeroImage';
import LSVNav    from '../../../components/elements/LSVNav/LSVNav';

const LSVContainerWrapper = memo(( { children } ) => {
  return(
    <div className="container main-container">
      <Helmet>
        <title>LSV | Soulclick Manager</title>
      </Helmet>
      <div className="row">
        <div className="col col-xs-12">
          <HeroImage imageUrl={ image } />
        </div>
      </div>

      <div className="row">
        <div className="col col-xs-12">
          <LSVNav />
        </div>
      </div>

      <div className="row">
        <div className="col col-xs-12">
          <div className="lsv-container">
            { children }
          </div>
        </div>
      </div>
    </div>
  )
});

export default LSVContainerWrapper;