import React, { memo } from 'react';

const Button = memo( ({ type = 'button', size = 'regular', viewType = 1, text, onClickAction = () => {} }) => {
  return (
    <button className={ `btn btn--${ size } btn--type-${ viewType }` }
            type={ type }
            onClick={ () => onClickAction() }>
      { text }
    </button>
  );
});

export default Button;