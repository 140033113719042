import React           from 'react';
import { useDispatch } from 'react-redux';

// import helpers
import { getPaymentMethodName }     from '../../../helpers/getPaymentMethod';
import { getPaymentTypeByKeyword }  from '../../../helpers/getPaymentTypeByKeyword';
import { tableHeaderNameByKeyword } from './table-helper';

// import utils
import { _t } from '../../../utils/i18n';

// import actions
import { showToast } from '../Toast/store/toastSlice';

export default function TableCopy({ data }) {
  const dispatch = useDispatch();

  /**
   * Copy the table to a clipboard
   */
  const handleClick = () => {
    // exclude and replace unnecessary fields
    const dataWithExcludedFields = data.map( item => {
      const { name } = getPaymentMethodName( item.payment_parameter );

      let updatedItem = {
        ...item,
        payment_parameter: name,
        donation_purpose: item.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : item.donation_purpose,
        donation_type: getPaymentTypeByKeyword( item.donation_type ),
      };

      // add translatable text to the status column
      if ( item.active !== undefined ) {
        updatedItem = {
          ...updatedItem,
          active: item.active ? _t( 'table_active' ) : _t( 'table_inactive' ),
        }
      }

      // add the interval column if it's needed
      if ( item.interval !== undefined ) {
        updatedItem = {
          ...updatedItem,
          interval: _t( 'table_interval_' + item.interval ),
        }
      }

      const { payment_method, resend_invoice_type, identifier, ...newItem } = updatedItem;

      return newItem;
    });

    // get headers from data
    const tableHeader = Object.keys( dataWithExcludedFields[0] ).map( value => {
      return '<th class="table__copy-table__head__cell">' + tableHeaderNameByKeyword( value ) + '</th>';
    });

    // get rows from data
    const tableRowsData = dataWithExcludedFields.map( item => {
      return Object.values( item ).map( value => {
        return '<td class="table__copy-table__body__cell">' + value + '</td>';
      });
    });

    // prepare rows
    const tableRows = tableRowsData.map( row => {
      return '<tr>' + row.join( '' ) + '</tr>';
    });

    // create the table markup
    const table = '<table class="table__copy-table">' +
      ' <thead><tr>' + tableHeader.join( '' ) + '</tr></thead>' +
      ' <tbody class="table__copy-table__body">' + tableRows.join( '' ) + '</tbody>' +
      '</table>';


    const copiedTableWrapper = document.querySelector( '.table__copy-table__wrapper' );

    copiedTableWrapper.innerHTML = table;

    const copiedTable = copiedTableWrapper.children[0];

    /**
     * The following code was taken from https://stackoverflow.com/a/60596107/9576555
     */
    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if ( document.createRange && window.getSelection ) {
      range = document.createRange();
      sel   = window.getSelection();

      sel.removeAllRanges();

      try {
        range.selectNodeContents( copiedTable );
        sel.addRange( range );
      } catch ( e ) {
        range.selectNode( copiedTable );
        sel.addRange( range );
      }

      document.execCommand( 'copy' );
    } else {
      dispatch( showToast( { isShown: true, type: 'warning', text: _t( 'table_copy_no_support' ) } ) );
    }

    sel.removeAllRanges();

    dispatch( showToast( { isShown: true, type: 'info', text: _t( 'table_copy_success' ) } ) );
  };

  return (
    <button className="table__action-btn"
            type="button"
            onClick={ () => handleClick() }>
      { _t( 'table_btn_copy' ) }
    </button>
  )
}