import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// import constants
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import { ALLDONATIONSPAYMENTS_FETCH, ALLDONATIONSPAYMENTS_DATE_UPDATE } from '../../../constants/dispatchTypes';

// import helpers
import { initialRequest, updateDateRequest } from '../../../api/helper';
import { getPaymentTypeByKeyword }           from '../../../helpers/getPaymentTypeByKeyword';

// import utils
import { isUserRemembered, getUserPackage } from '../../../utils/Auth';
import { _t, setMetaTitle }                 from '../../../utils/i18n';

// import config
import { sortByAmount, sortByDate } from '../../../components/elements/Table/config';

// import components
import TableActions       from '../../../components/elements/Table/TableActions';
import TableNameAddress   from '../../../components/elements/Table/TableNameAddress';
import TablePaymentMethod from '../../../components/elements/Table/TablePaymentMethod';

// define API urls
const defaultUrl = 'dashboard_stats_api/all-donations/';
const updateUrl  = 'dashboard_stats_api/update-all-donations/';

// define table options
const tableOptions = [
  {
    id: 'actions',
    name: _t( 'table_actions' ),
    selector: row => <TableActions row={ row } />,
    width: '65px',
  },
  {
    id: 'order-nr',
    name: _t( 'table_order_num' ),
    selector: row => row.order_nr,
    sortable: true,
    width: '100px',
  },
  {
    id: 'date',
    name: _t( 'table_date' ),
    selector: row => row.date,
    sortable: true,
    width: '80px',
    sortFunction: sortByDate,
  },
  {
    id: 'name',
    name: _t( 'table_name' ),
    selector: row => <TableNameAddress address={ row.full_address } />,
  },
  {
    id: 'amount',
    name: _t( 'table_amount' ),
    selector: row => row.amount,
    sortable: true,
    sortFunction: sortByAmount
  },
  {
    id: 'payment-type',
    name: _t( 'table_payment_type' ),
    selector: row => <span title={ getPaymentTypeByKeyword( row.donation_type ) }>{ getPaymentTypeByKeyword( row.donation_type ) }</span>,
  },
  {
    id: 'product',
    name: getUserPackage( isUserRemembered() ) === 'smart' ? _t( 'table_product_smart' ) : _t( 'table_product' ),
    selector: row => <span title={ row.product }>{ row.product }</span>,
  },
  {
    id: 'donation-purpose',
    name: _t( 'table_donation_purpose' ),
    selector: row => row.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : row.donation_purpose,
  },
  {
    id: 'payment-method',
    name: _t( 'table_payment_method' ),
    selector: row => <TablePaymentMethod data={ row } />,
    width: '160px',
  }
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchAllDonations = createAsyncThunk(
  ALLDONATIONSPAYMENTS_FETCH,
  async ( args, thunkAPI ) =>
    initialRequest( args, thunkAPI, { defaultUrl, updateUrl }, false )
);

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: ALLDONATIONSPAYMENTS_DATE_UPDATE
};

export const updatingAllDonations = dateRange => updateDateRequest( dateRange, updatingData, false );

const allDonationsSlice = createSlice({
  name: 'allDonations',
  initialState: {
    status: IDLE_STATUS,
    metaTitle: '',
    tableStatus: IDLE_STATUS,
    topStatsData: [],
    topProductsChartData: [],
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      const { payload } = action;

      state.status      = payload;
      state.tableStatus = payload;
    },
    dateUpdated: ( state, action ) => {
      const { topStatsData, topProductsChartData, tableData } = action.payload;

      state.topStatsData         = topStatsData !== undefined ? topStatsData : state.topStatsData;
      state.topProductsChartData = topProductsChartData !== undefined ? topProductsChartData : state.topProductsChartData;
      state.tableData            = tableData !== undefined ? tableData : state.tableData;
      state.status               = SUCCEEDED_STATUS;
      state.tableStatus          = SUCCEEDED_STATUS;
    }
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchAllDonations.pending, state => {
        state.status      = LOADING_STATUS;
        state.tableStatus = LOADING_STATUS;
      })
      .addCase( fetchAllDonations.fulfilled, ( state, action ) => {
        const { topStatsData, topProductsChartData, tableData, metas } = action.payload;

        state.metaTitle            = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topStatsData         = topStatsData !== undefined ? topStatsData : state.topStatsData;
        state.topProductsChartData = topProductsChartData !== undefined ? topProductsChartData : state.topProductsChartData;
        state.tableData            = tableData !== undefined ? tableData : state.tableData;
        state.status               = SUCCEEDED_STATUS;
        state.tableStatus          = SUCCEEDED_STATUS;
      })
      .addCase( fetchAllDonations.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const { statusUpdated: allDonationsStatusUpdating } = allDonationsSlice.actions;

export default allDonationsSlice.reducer;