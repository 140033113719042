import React, { memo } from 'react';

// import helper
import { getPaymentTypeByKeyword } from '../../../helpers/getPaymentTypeByKeyword';
import { swissNumberFormat }       from '../../../helpers/swissNumberFormat';

// import utils
import { getUser, isUserRemembered } from '../../../utils/Auth';

const LatestDonationItem = memo( ( { data } ) => {
  const { username, amount, product, productType, date, product_image_url } = data;

  // get the user currency
  const isRemembered = isUserRemembered();
  const { currency } = getUser( isRemembered );

  return (
    <div className="latest-donation-item">
      <img className="latest-donation-item__img"
           src={ product_image_url }
           alt={ product } />
      <div className="latest-donation-item__data">
        <span className="latest-donation-item__user-name">{ username }</span>
        <div className="latest-donation-item__amount">
          <span className="latest-donation-item__amount__value">{ currency } { swissNumberFormat( amount ) }</span>
          <span className="latest-donation-item__amount__product">{ product }</span>
        </div>
        <p className="latest-donation-item__date">{ getPaymentTypeByKeyword( productType ) }: { date }</p>
      </div>
    </div>
  )
});

export default LatestDonationItem;